import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  deleteDoc,
  doc,
  where,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { EditableRow } from "./EditableRow";
import { ReadOnlyRow } from "./ReadOnlyRow";
import { CSVLink } from "react-csv";
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AiOutlineSearch } from "react-icons/ai";

// --------------------------  HELPER FUNCTIONS  -------------------------- //

const Visitors = () => {
  const [visitorData, setVisitorData] = useState([]);
  const [currentRow, setCurrentRow] = useState(-1);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [csvData, setCsvData] = useState({
    filename: "",
    data: "",
  });

  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    //If the selected date is not valid then exit the function (without this function, it caused the app to crash because of a RangeError)
    if (!(selectedDate instanceof Date && !isNaN(selectedDate))) return;

    //If there is a user logged in, then get all of his visit details
    if (user) {
      const collectionRef = collection(
        db,
        "companies",
        user.email.split("@").pop(),
        "visits"
      );

      //Firebase query to query all the visits corresponding to the selected date from the date picker
      const q = query(
        collectionRef,
        orderBy("visitDate", "desc"),
        where(
          "visitDate",
          ">=",
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate()
          )
        ),
        where(
          "visitDate",
          "<",
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate() + 1
          )
        )
      );
      //console.log(q);

      //Firebase query to get all the visitors
      const unsub = onSnapshot(q, (querySnapshot) => {
        let tempArr = [];
        querySnapshot.forEach((doc) => {
          //console.log(`Doc ID: ${doc.id} ==> Doc Data`, doc.data());
          // if(doc.data().checkInTime) doc.data()['status'] = "Checked-in"
          // else if (doc.data().checkOutTime) doc.data()['status'] = "Checked-out"
          // else doc.data()['status'] = "Expected"

          tempArr.push({ id: doc.id, ...doc.data() });
        });

        setVisitorData([...tempArr]);
      });

      return () => unsub();
    }
  }, [selectedDate]);

  useEffect(() => {
    //Function to format visitor data
    if (visitorData.length > 0) {
      const formattedData = visitorData.map((item) => ({
        ...item,
        visitDate: new Timestamp(
          item.visitDate.seconds,
          item.visitDate.nanoseconds
        ).toDate(),
      }));

      //Create a CSV file with a custom filename and write visitor data into the file
      setCsvData({
        filename: `visitors_report_${("0" + selectedDate.getDate()).slice(-2)}${
          month[selectedDate.getMonth()]
        }${selectedDate.getFullYear()}.csv`,
        data: formattedData,
      });
    }
  }, [visitorData, selectedDate]);

  const handleEdit = (index) => {
    setCurrentRow(index + 1);
  };

  const handleSave = async (form) => {
    setCurrentRow(0);

    const userDoc = doc(
      db,
      "companies",
      user.email.split("@").pop(),
      "visits",
      form.id
    );

    //If, the check-out time is undefined, then update the document without check-out field.
    //Else, check if the check-time is earlier than the check-out time and update the document by adding a check-out field.
    if (
      (typeof form.checkOut == "undefined" &&
        typeof form.checkIn == "undefined") ||
      (form.checkOut == null && form.checkIn == null)
    ) {
      const newFields = {
        hostEmail: form.host.toLowerCase(),
        visitorEmail: form.visitor.toLowerCase(),
        visitorCompany: form.company.toLowerCase(),
      };

      await updateDoc(userDoc, newFields);
    } else if (
      typeof form.checkOut == "undefined" ||
      form.checkOut == null
    ) {
      //Format the check-in time
      const checkInTime = new Date(form.visitDate.toDate());
      checkInTime.setHours(parseInt(form.checkIn.split(":")[0]));
      checkInTime.setMinutes(parseInt(form.checkIn.split(":")[1]));

      const newFields = {
        checkInTime: Timestamp.fromDate(checkInTime),
        hostEmail: form.host.toLowerCase(),
        visitorEmail: form.visitor.toLowerCase(),
        visitorCompany: form.company.toLowerCase(),
      };

      await updateDoc(userDoc, newFields);
    } else {
      //Format the check-in time
      const checkInTime = new Date(form.checkInDate.toDate());
      checkInTime.setHours(parseInt(form.checkIn.split(":")[0]));
      checkInTime.setMinutes(parseInt(form.checkIn.split(":")[1]));

      const checkoutTime = new Date(form.checkInDate.toDate());
      checkoutTime.setHours(parseInt(form.checkOut.split(":")[0]));
      checkoutTime.setMinutes(parseInt(form.checkOut.split(":")[1]));

      if (checkInTime < checkoutTime) {
        const newFields = {
          checkInTime: Timestamp.fromDate(checkInTime),
          checkoutTime: Timestamp.fromDate(checkoutTime),
          hostEmail: form.host.toLowerCase(),
          visitorEmail: form.visitor.toLowerCase(),
          visitorCompany: form.company.toLowerCase(),
        };

        await updateDoc(userDoc, newFields);
      } else window.alert("Please insert a correct timeframe.");
    }
  };

  const handleCancel = () => {
    setCurrentRow(0);
  };

  const handleDelete = async (index, item) => {
    const visitorDoc = doc(
      db,
      "companies",
      user.email.split("@").pop(),
      "visits",
      item.id
    );

    if (window.confirm("Are you sure you want to delete this visit?")) {
      await deleteDoc(visitorDoc);
    } else return;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // --------------------------  USER INTERFACE  -------------------------- //

  return (
    <HelmetProvider>
      <main className="text-gray-600 body-font">
        <Helmet>
          <title>Tokjo | Visitors overview</title>
        </Helmet>
        <div className="container px-5 py-24 mx-auto">
          <div className="flex justify-between">
            <input
              className="px-4 py-2 mb-2 text-xs bg-purple-400 text-white text-center rounded
          uppercase tracking-wider font-semibold hover:bg-purple-300"
              id="date"
              type={"date"}
              onChange={(e) => setSelectedDate(new Date(e.target.value))}
            />
            {/* <form className="relative block">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <AiOutlineSearch className="h-5 w-5 mb-2"/>
              </span>
              <input
                className="w-full py-2 pl-11 pr-4 mb-2 text-sm bg-white text-black rounded tracking-wider font-semibold"
                placeholder="Search a visitor, company or host"
                id="searchBar"
                type="text"
              />
            </form> */}
            {/* If there aren't any visitors for a specific day. Then hide the export csv button */}
            {visitorData.length > 0 && (
              <CSVLink
                data={csvData.data}
                filename={csvData.filename}
                className="px-4 py-2 text-xs bg-purple-400 text-white rounded
        uppercase tracking-wider font-semibold hover:bg-purple-300 mb-2"
              >
                Export CSV
              </CSVLink>
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <ul className="divide-y-2 divide-gray-100 overflow-x-auto w-full rounded bg-white">
              <li className="grid grid-cols-7 py-3 text-sm text-gray-500 font-semibold">
                <p className="font-bold px-4">VISITOR</p>
                <p className="font-bold px-4">COMPANY</p>
                <p className="font-bold px-4">HOST</p>
                <p className="font-bold px-4">STATUS</p>
                <p className="font-bold px-4">CHECK-IN</p>
                <p className="font-bold px-4">CHECK-OUT</p>
                <p className="font-bold px-4">ACTIONS</p>
              </li>
              {visitorData.map((item, index) => (
                <React.Fragment key={item.id}>
                  {currentRow && currentRow === index + 1 ? (
                    <EditableRow
                      data={item}
                      handleSave={(form) => {
                        handleSave(form);
                      }}
                      handleCancel={() => {
                        handleCancel();
                      }}
                    />
                  ) : (
                    <ReadOnlyRow
                      data={item}
                      handleEdit={() => {
                        handleEdit(index);
                      }}
                      handleDelete={() => {
                        handleDelete(index, item);
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </ul>
          </form>
        </div>
      </main>
    </HelmetProvider>
  );
};

export default Visitors;
