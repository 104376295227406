import React from 'react'
import { Link } from "react-router-dom";


function NotFound() {
  return (
    <main className="relative overflow-hidden h-screen relative">
        <div className="container mx-auto h-screen pt-32 md:pt-0 px-6 z-10 flex items-center justify-between">
            <div className="container mx-auto px-6 flex flex-col-reverse lg:flex-row justify-between items-center relative">
                <div className="w-full mb-16 md:mb-8 text-center lg:text-left">
                    <h1 className="font-light font-sans text-center lg:text-left text-5xl lg:text-8xl mt-12 md:mt-0 text-gray-700">Oops! You appear to be lost.</h1>
                    <Link to="/dashboard">
                      <p className="px-2 py-2 w-36 mt-16 font-light transition ease-in duration-200 text-white hover:bg-purple-300 border-2 text-lg border-gray-700 bg-purple-400 focus:outline-none">Go back home</p>
                    </Link>
                </div>
            </div>
        </div>
    </main>
  )
}

export default NotFound