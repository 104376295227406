import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { FiActivity } from "react-icons/fi";

const Header = () => {
  const [navOpen, setNavOpen] = React.useState(false);

  const handleLogout = () => {
    signOut(auth);
  };

  const [user, loading, error] = useAuthState(auth);

  return (
    <div className="relative antialiased bg-gray-100">
      <nav className="p-4 md:py-8 xl:px-0 md:container md:mx-w-6xl md:mx-auto">
        <div className="hidden lg:flex lg:justify-between lg:items-center">
          <Link className="flex items-start gap-2 group" to="/dashboard">
            <div className="bg-purple-400 text-white p-2 rounded-md">
              <FiActivity className="h-6 w-6" />
            </div>
            <p className="text-sm font-light uppercase">
              Tokjo
              <span className="text-base block font-bold tracking-widest">
                Dashboard
              </span>
            </p>
          </Link>
          <ul className="flex items-center space-x-4 text-sm font-semibold">
            <li>
              <Link
                className="px-2 xl:px-4 py-2 text-gray-600 rounded-md hover:bg-purple-200"
                to="/dashboard"
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                className="px-2 xl:px-4 py-2 text-gray-600 rounded-md hover:bg-purple-200"
                to="/visitors"
              >
                Visitors
              </Link>
            </li>
            <li>
              <Link
                className="px-2 xl:px-4 py-2 text-gray-600 rounded-md hover:bg-purple-200"
                to="/visitors/new"
              >
                Visitor registration
              </Link>
            </li>
          </ul>
          <ul className="flex items-center gap-6">
            <li>
              <Link
                className="text-sm font-sans text-gray-800 font-semibold tracking-wider"
                to="#"
              >
                {user.email}
              </Link>
            </li>
            <li>
              <div
                className="p-2 rounded hover:bg-purple-200"
                onClick={handleLogout}
              >
                <svg
                  className="w-5 h-5 stroke-current text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  ></path>
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <div className="lg:hidden relative flex justify-between w-full">
        <Link className="flex items-start gap-2 group" to="#">
          <div className="bg-purple-400 text-white p-3 rounded-md">
          <FiActivity className="h-6 w-6" />
          </div>
          <p className="text-sm font-light uppercase">
            Tokjo
            <span className="text-base block font-bold tracking-widest">
              Dashboard
            </span>
          </p>
        </Link>
        <button
          onClick={() => {
            setNavOpen(!navOpen);
          }}
          className="bg-gray-200 p-3 rounded-md"
          type="button"
        >
          {navOpen ? (
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          ) : (
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              ></path>
            </svg>
          )}
        </button>
        {navOpen ? (
          <div className="absolute top-14 left-0 right-0 w-full bg-white rounded-md border">
            <ul className="p-4">
              <li className="px-4 py-2 rounded hover:bg-purple-200">
                <Link className="flex items-center gap-4" to="/dashboard">
                  Dashboard
                </Link>
              </li>
              <li className="px-4 py-2 rounded hover:bg-purple-200">
                <Link className="flex items-center gap-4" to="/visitors">
                  Visitors
                </Link>
              </li>
              <li className="px-4 py-2 rounded hover:bg-purple-200">
                <Link className="flex items-center gap-4" to="/visitors/new">
                  Visitor registration
                </Link>
              </li>
              <li
                className="px-4 py-2 rounded hover:bg-purple-200"
                onClick={handleLogout}
              >
                Log out
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
