import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router-dom";
import { auth } from "../firebase";

const ProtectedRoute = ({ children }) => {
  const [user, loading, error] = useAuthState(auth);
  //console.log("Check user in Private: ", user);
  if (undefined === user) {
    //console.log("User is undefined");
  }
  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;
