import React from "react";

function VisitorAdded(props) {
  return props.trigger ? (
    <div className="bg-green-600">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-green-400"></span>
            <p className="ml-3 font-medium text-white truncate">
              <span className="md:inline">
                Visitor successfully added!
              </span>
            </p>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              onClick={() => props.setTrigger(false)}
              className="bg-transparent text-green-200 p-3 rounded-md"
              type="button"
            >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
            
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : ""; 
}

export default VisitorAdded