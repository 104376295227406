import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from "react-router";
import { auth } from "../../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Helmet, HelmetProvider } from "react-helmet-async";

// --------------------------  HELPER FUNCTIONS  -------------------------- //

const Login = () => {
  const [user, loading, error] = useAuthState(auth);

  const HandleSubmit = async (event) => {
    event.preventDefault();

    //Firebase function to send password reset email
    const auth = getAuth();
    sendPasswordResetEmail(auth, event.target.elements.email.value)
      .then(() => {
        event.target.elements.email.value = "";
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  // --------------------------  USER INTERFACE  -------------------------- //

  return user ? (
    <Navigate to="/dashboard" replaced={true} />
  ) : (
    <HelmetProvider>
      <div className="bg-gray-100">
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <div className="flex flex-col md:flex-row h-full items-center">
          <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center">
            <div className="w-full h-100">
              <h1 className="text-xl md:text-2xl font-bold leading-tight mt-12">
                Reset password
              </h1>
              <form className="mt-6" onSubmit={HandleSubmit}>
                <div>
                  <label className="block text-gray-700">
                    Enter your user account's email address and we will send you
                    a password reset link.
                  </label>
                  <input
                    className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-purple-500 focus:bg-white focus:outline-none"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="john_doe@yourcompany.com"
                    autoFocus="autofocus"
                    autoComplete="autocomplete"
                    required="required"
                  />
                </div>
                <button
                  className="w-full block bg-purple-500 hover:bg-purple-400 focus:bg-purple-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
                  type="submit"
                >
                  Send password reset email{" "}
                </button>
              </form>
            </div>
          </div>
          <div className="justify-center items-center bg-gradient-to-tr from-purple-400 to-blue-700 hidden lg:flex w-full md:w-1/2 xl:w-2/3 h-screen">
            <div>
              <h1 className="text-white font-bold text-6xl font-sans">Tokjo</h1>
              <p className="text-white mt-1 text-4xl">Smart visitor system</p>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Login;
