import React, { useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdOutlineCancel } from "react-icons/md";

export const EditableRow = ({ data, handleSave, handleCancel }) => {
  const [form, setForm] = useState({
    id: data.id,
    status: data.status,
    visitDate: data.visitDate,
    checkInDate: data.checkInTime && data.checkInTime,
    checkOutDate: data.checkoutTime && data.checkoutTime,
    checkIn:
      data.checkInTime &&
      ("0" + data.checkInTime.toDate().getHours()).slice(-2) +
        ":" +
        ("0" + data.checkInTime.toDate().getMinutes()).slice(-2),
    checkOut:
      data.checkoutTime &&
      ("0" + data.checkoutTime.toDate().getHours()).slice(-2) +
        ":" +
        ("0" + data.checkoutTime.toDate().getMinutes()).slice(-2),
    visitor: data.visitorEmail,
    company: data.visitorCompany,
    host: data.hostEmail,
  });
  const onChange = (e) => {
    setForm((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  return (
    <li className="grid grid-cols-7 py-3 text-sm text-gray-500 font-semibold bg-gray-50 gap-2">
      <input
        className="md:text-base rounded border px-4 text-gray-600 ml-2"
        name="visitor"
        onChange={onChange}
        value={form.visitor}
      />
      <input
        className="md:text-base rounded border px-4 text-gray-600"
        name="company"
        onChange={onChange}
        value={form.company}
      />
      <input
        className="md:text-base rounded border px-4 text-gray-600"
        name="host"
        onChange={onChange}
        value={form.host}
      />
      <select
        className="form-select block w-full text-base font-normal text-gray-700 
        bg-white bg-clip-padding bg-no-repeat border border-solid 
        border-gray-300 rounded transition ease-in-out m-0 
        max-h-7 focus:text-gray-700 focus:bg-white 
        focus:border-blue-600 focus:outline-none"
        aria-label="Default select example"
        defaultValue={"0"}
        name="status"
        onChange={onChange}
      >
        <option value="0">Expected</option>
        <option value="1">Checked-in</option>
        <option value="2">Checked-out</option>
      </select>
      <input
        className="md:text-base rounded border px-4 text-gray-600"
        name="checkIn"
        onChange={onChange}
        value={form.checkIn}
        type="time"
      />

      <input
        name="checkOut"
        className="md:text-base rounded border px-4 text-gray-600"
        onChange={onChange}
        value={form.checkOut}
        type="time"
      />
      <div className="flex flex-row gap-6 text-center">
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            handleSave(form);
          }}
        >
          <BiSave className="h-5 w-5" />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleCancel();
          }}
        >
          <MdOutlineCancel className="h-5 w-5" />
        </button>
      </div>
    </li>
  );
};
