import React from "react";
import { BiEditAlt, BiTrash } from "react-icons/bi";

const handleStatus = (data) => {
  if (data.checkoutTime && data.checkInTime) {
    data.status = "Checked out";
    return data.status;
  } else if (data.checkoutTime == null && data.checkInTime) {
    data.status = "Checked in";
    return data.status;
  } else {
    data.status = "Expected";
    return data.status;
  }
};

export const ReadOnlyRow = ({ data, handleEdit, handleDelete }) => {
  return (
    <li className="grid grid-cols-7 py-3 text-sm text-gray-500 font-semibold bg-gray-50 gap-2">
      <p className="px-4 text-gray-600 truncate capitalize">
        {data.visitorEmail}
      </p>
      <p className="px-4 text-gray-600 truncate capitalize">
        {data.visitorCompany}
      </p>
      <p className="px-4 text-gray-600 truncate capitalize">{data.hostEmail}</p>
      <p className="px-4 text-gray-600 font-bold">{handleStatus(data)}</p>
      <p className="px-4 text-gray-600">
        {data.checkInTime &&
          ("0" + data.checkInTime.toDate().getHours()).slice(-2) +
            ":" +
            ("0" + data.checkInTime.toDate().getMinutes()).slice(-2)}
      </p>
      <p className="px-4 text-gray-600">
        {data.checkoutTime &&
          ("0" + data.checkoutTime.toDate().getHours()).slice(-2) +
            ":" +
            ("0" + data.checkoutTime.toDate().getMinutes()).slice(-2)}
      </p>
      <div className="flex flex-row gap-6 text-center">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleEdit();
          }}
        >
          <BiEditAlt className="h-5 w-5" />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            handleDelete();
          }}
        >
          <BiTrash className="h-5 w-5" />
        </button>
      </div>
    </li>
  );
};
