import "./index.css";
import Login from "./components/login/Login";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import Dashboard from "./components/dashboard/Dashboard";
import Visitors from "./components/visitors/Visitors";
import MainLayout from "./components/mainLayout/MainLayout";
import CheckIn from "./components/checkIn/CheckIn";
import NotFound from "./components/notFound/NotFound";

import { Routes, Route } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function App() {
  const [user, loading, error] = useAuthState(auth);

  if (!user) {
    return (
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/password_reset" element={<ForgotPassword />}/>
      </Routes>
    );
  }
  return (
    user && (
      <Routes>
        <Route
          element={
            <ProtectedRoute>
              <MainLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/visitors" element={<Visitors />} />
          <Route path="/visitors/new" element={<CheckIn />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    )
  );
}

export default App;
