import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Chart, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { auth, db } from "../../firebase";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
Chart.register(ArcElement);

// --------------------------  HELPER FUNCTIONS  -------------------------- //

const Dashboard = () => {
  let currentDate = new Date();

  const [user, loading, error] = useAuthState(auth);
  const [visitorData, setVisitorData] = useState([]);

  //Data used for the chart
  const data = {
    labels: ["HR", "Finance", "Sales", "Delivery"],
    datasets: [
      {
        label: "Total visitors per department",
        data: [148, 150, 130, 170],
        backgroundColor: ["#3B82F6", "#10B981", "#6366F1", "#F59E0B"],
      },
    ],
  };

  useEffect(() => {
    //console.log("Getting data");

    const collectionRef = collection(
      db,
      "companies",
      user.email.split("@").pop(),
      "visits"
    );

    //Firestore query to get 10 latest collection ordered by visitDate in descending order
    const q = query(collectionRef, orderBy("visitDate", "desc"), limit(10));
    //console.log(q);

    const unsub = onSnapshot(q, (querySnapshot) => {
      let tempArr = [];
      querySnapshot.forEach((doc) => {
        //console.log(`Doc ID: ${doc.id} ==> Doc Data`, doc.data());

        tempArr.push(doc.data());
      });
      setVisitorData([...tempArr]);
    });

    return () => unsub();
  }, []);

  //Count number of visitors this YEAR
  const countYearVisitor = (visitorData) => {
    let index = 0;
    visitorData.forEach((e) => {
      if (e.visitDate.toDate().getFullYear() === currentDate.getFullYear()) {
        index += 1;
      }
    });
    return index;
  };

  //Count number of visitors this YEAR
  const countMonthVisitor = (visitorData) => {
    let index = 0;

    visitorData.forEach((e) => {
      if (e.visitDate.toDate().getMonth() === currentDate.getMonth()) {
        index += 1;
      }
    });
    return index;
  };

  //Count number of visitors this WEEK
  const countWeekVisitor = (visitorData) => {
    let index = 0;

    visitorData.forEach((e) => {
      if (
        getWeekNumber(e.visitDate.toDate()) === getWeekNumber(currentDate) &&
        e.visitDate.toDate().getFullYear() === currentDate.getFullYear()
      ) {
        index += 1;
      }
    });
    return index;
  };

  const getWeekNumber = (date) => {
    let startDate = new Date(date.getFullYear(), 0, 1);
    let days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));

    let weekNumber = Math.ceil(days / 7);
    return weekNumber;
  };

  //Count number of check-in visitors for TODAY
  const countCurrentlyCheckIn = (visitorData) => {
    let index = 0;
    visitorData.forEach((e) => {
      if (
        e.checkInTime &&
        !e.checkoutTime &&
        e.checkInTime.toDate().getDate() === currentDate.getDate()
      ) {
        index += 1;
      }
    });
    return index;
  };

  //Count number of visitors in total for TODAY
  const countTodayVisitors = (visitorData) => {
    let index = 0;
    visitorData.forEach((e) => {
      if (
        e.visitDate.toDate().getFullYear() === currentDate.getFullYear() &&
        e.visitDate.toDate().getMonth() === currentDate.getMonth() &&
        e.visitDate.toDate().getDate() === currentDate.getDate()
      ) {
        index += 1;
      }
    });
    return index;
  };

  const handleStatus = (data) => {
    if (data.checkoutTime && data.checkInTime) {
      data.status = "Checked out";
      return data.status;
    } else if (data.checkoutTime == null && data.checkInTime) {
      data.status = "Checked in";
      return data.status;
    } else {
      data.status = "Expected";
      return data.status;
    }
  };

  // --------------------------  USER INTERFACE  -------------------------- //

  return (
    <HelmetProvider>
      <main className="container mx-w-6xl mx-auto py-4">
        <Helmet>
          <title>Tokjo | Dashboard</title>
          <meta
            name="description"
            content="Welcome on the Tokjo dashboard. An interactive dashboard showing visitors statistics."
          />
        </Helmet>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-4 xl:p-0 gap-4 xl:gap-6 mb-5">
          <div className="col-span-1 md:col-span-2 lg:col-span-4 flex justify-between">
            <h2 className="text-xs md:text-sm text-gray-700 font-bold tracking-wide md:tracking-wider">
              Visitors Statistics
            </h2>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-50">
            <div className="flex justify-between items-start">
              <div className="flex flex-col">
                <p className="text-xs text-gray-600 tracking-wide">
                  Number of visitors in {new Date().getFullYear()}
                </p>
                <h3 className="mt-1 text-lg text-blue-500 font-bold">
                  {countYearVisitor(visitorData)}
                </h3>
                <span className="mt-4 text-xs text-gray-500">
                  Last visitor 3 hours ago
                </span>
              </div>
              <div className="bg-blue-500 p-2 md:p-1 xl:p-2 rounded-md">
                <img
                  className="w-auto h-8 md:h-6 xl:h-8 object-cover"
                  src="images/user_white.png"
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-50">
            <div className="flex justify-between items-start">
              <div className="flex flex-col">
                <p className="text-xs text-gray-600 tracking-wide">
                  Currently checked-in
                </p>
                <h3 className="mt-1 text-lg text-blue-500 font-bold">
                  {countCurrentlyCheckIn(visitorData)}
                </h3>
                <span className="mt-4 text-xs text-gray-500">
                  Last visitor 10 minutes ago
                </span>
              </div>
              <div className="bg-blue-500 p-2 md:p-1 xl:p-2 rounded-md">
                <img
                  className="w-auto h-8 md:h-6 xl:h-8 object-cover"
                  src="images/user_white.png"
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-50">
            <div className="flex justify-between items-start">
              <div className="flex flex-col">
                <p className="text-xs text-gray-600 tracking-wide">
                  Today's visitors
                </p>
                <h3 className="mt-1 text-lg text-blue-500 font-bold">
                  {countTodayVisitors(visitorData)}
                </h3>
                <span className="mt-4 text-xs text-gray-500">
                  Last visitor 10 minutes ago
                </span>
              </div>
              <div className="bg-blue-500 p-2 md:p-1 xl:p-2 rounded-md">
                <img
                  className="w-auto h-8 md:h-6 xl:h-8 object-cover"
                  src="images/user_white.png"
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-xl border border-gray-50">
            <div className="flex justify-between items-start">
              <div className="flex flex-col">
                <p className="text-xs text-gray-600 tracking-wide">
                  Busiest day
                </p>
                <h3 className="mt-1 text-lg text-blue-500 font-bold">
                  {" "}
                  Tuesday
                </h3>
              </div>
              <div className="bg-blue-500 p-2 md:p-1 xl:p-2 rounded-md">
                <img
                  className="w-auto h-8 md:h-6 xl:h-8 object-cover"
                  src="images/user_white.png"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-5 items-start px-4 xl:p-0 gap-y-4 md:gap-6">
          <div className="col-start-1 col-end-5"></div>
          <div className="col-span-2 bg-white p-6 rounded-xl border border-gray-50 flex flex-col space-y-6">
            <h2 className="text-xs md:text-sm text-gray-700 font-bold tracking-wide">
              Visitors Summary
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 flex justify-between items-center">
              <div className="p-4 cursor-pointer border">
                <span className="text-xs text-gray-500 font-semibold">
                  Today
                </span>
                <h2 className="text-gray-800 font-bold tracking-wider">
                  {countTodayVisitors(visitorData)}
                </h2>
              </div>
              <div className="p-4 cursor-pointer border">
                <span className="text-xs text-gray-500 font-semibold">
                  This week
                </span>
                <h2 className="text-gray-800 font-bold tracking-wider">
                  {countWeekVisitor(visitorData)}
                </h2>
              </div>
              <div className="p-4 cursor-pointer border">
                <span className="text-xs text-gray-500 font-semibold">
                  This month
                </span>
                <h2 className="text-gray-800 font-bold tracking-wider">
                  {countMonthVisitor(visitorData)}
                </h2>
              </div>
            </div>
            <Doughnut data={data} />
          </div>
          <div className="col-span-3 bg-white p-6 rounded-xl border border-gray-50 flex flex-col space-y-6">
            <div className="flex justify-between items-center">
              <h2 className="text-sm text-gray-600 font-bold tracking-wide">
                Latest Visitors
              </h2>
              <div className="flex gap-4">
                <Link
                  to={"/visitors/new"}
                  className="px-4 py-2 text-xs bg-purple-400 text-white rounded uppercase tracking-wider font-semibold hover:bg-purple-300"
                >
                  add
                </Link>
                <Link
                  to={"/visitors"}
                  className="px-4 py-2 text-xs bg-purple-400 text-white rounded uppercase tracking-wider font-semibold hover:bg-purple-300"
                >
                  More
                </Link>
              </div>
            </div>
            <ul className="divide-y-2 divide-gray-100 overflow-x-auto w-full">
              <li className="grid grid-cols-4 py-3 text-sm text-gray-500 font-semibold">
                <p className="font-bold px-4">VISITOR</p>
                <p className="font-bold px-4">COMPANY</p>
                <p className="font-bold px-4">HOST</p>
                <p className="font-bold px-4">STATUS</p>
              </li>
              {visitorData.map((item, index) => (
                <li
                  className="grid grid-cols-4 py-3 text-sm text-gray-500 font-semibold"
                  key={index}
                >
                  <p className="px-4 text-gray-600 truncate capitalize">
                    {item.visitorEmail}
                  </p>
                  <p className="px-4 text-gray-600 truncate capitalize">
                    {item.visitorCompany}
                  </p>
                  <p className="px-4 text-gray-600 truncate capitalize">
                    {item.hostEmail}
                  </p>
                  <p className="px-4 text-gray-600 font-bold">
                    {handleStatus(item)}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </HelmetProvider>
  );
};

export default Dashboard;
